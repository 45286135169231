import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

//  vars

const medWidth = 768;

let tabLinks;
let tabContents;
let tabList;
let index;
let width;
let offsetLeft;

function setActive(tabLink) {
  index = tabLinks.indexOf(tabLink);

  // remove current actives
  const prevTab = document.querySelectorAll(
    '[data-tab-link][data-active="true"]'
  )[0];
  if (prevTab) prevTab.setAttribute('data-active', false);
  const prevContent = document.querySelectorAll(
    '[data-tab-content][data-active="true"]'
  )[0];
  if (prevContent) prevContent.setAttribute('data-active', false);

  // add new actives
  tabLinks[index].setAttribute('data-active', true);
  tabContents[index].setAttribute('data-active', true);
}

function scrollTo(tabLink) {
  width = window.innerWidth;
  if (width < medWidth) {
    offsetLeft = tabLink.offsetLeft - 100;
    tabList.scrollTo({ left: offsetLeft, behavior: 'smooth' });
  }
}

// show/hide tabs

function toggleTabs() {
  tabLinks.forEach(tabLink => {
    tabLink.addEventListener('click', e => {
      e.preventDefault();
      setActive(tabLink);
      scrollTo(tabLink);
    });
  });

  // Activate the first one
  setActive(tabLinks[0]);
}

export default function() {
  tabLinks = Array.prototype.slice.call(
    document.querySelectorAll('[data-tab-link]')
  );
  tabContents = Array.prototype.slice.call(
    document.querySelectorAll('[data-tab-content]')
  );
  tabList = document.querySelectorAll('[data-tab-list]')[0];

  if (tabLinks.length) {
    toggleTabs();
  }
}
