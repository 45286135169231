// vars

const medWidth = 768;

let menu;
let menuOverlay;
let utilityMenu;
let dropdownGroups;
let dropdownParents;
let body;
let banner;

let isActive = false;

let dropdownSub;
let dropdownParent;
let activeSub;
let activeParent;
let links;
let lastLink;
let width;

function resetAll() {
  if (isActive) {
    activeSub = document.querySelectorAll(
      '[data-dropdown-sub][data-active="true"]'
    )[0];
    activeParent = document.querySelectorAll(
      '[data-dropdown-parent][data-active="true"]'
    )[0];
    activeSub.removeAttribute('data-active');
    activeParent.removeAttribute('data-active');

    body.removeAttribute('data-fixed');
    utilityMenu.removeAttribute('data-active');
    menuOverlay.removeAttribute('data-active');

    if (banner) {
      banner.removeAttribute('data-active');
    }

    isActive = false;
  }
}

function setActiveParent(dropdownParentElem) {
  width = window.innerWidth;
  if (width >= medWidth) {
    dropdownSub = dropdownParentElem.parentNode.querySelectorAll(
      '[data-dropdown-sub]'
    )[0];
    dropdownSub.setAttribute('data-active', true);
    dropdownParentElem.setAttribute('data-active', true);

    body.setAttribute('data-fixed', true);
    utilityMenu.setAttribute('data-active', true);
    menuOverlay.setAttribute('data-active', true);

    if (banner) {
      banner.setAttribute('data-active', true);
    }

    isActive = true;
  }
}

function setActiveGroup(dropdownGroup) {
  width = window.innerWidth;
  if (width >= medWidth) {
    dropdownParent = dropdownGroup.querySelectorAll(
      '[data-dropdown-parent]'
    )[0];
    dropdownSub = dropdownGroup.querySelectorAll('[data-dropdown-sub]')[0];
    dropdownSub.setAttribute('data-active', true);
    dropdownParent.setAttribute('data-active', true);

    body.setAttribute('data-fixed', true);
    utilityMenu.setAttribute('data-active', true);
    menuOverlay.setAttribute('data-active', true);

    if (banner) {
      banner.setAttribute('data-active', true);
    }

    isActive = true;
  }
}

function keyboardEsc() {
  document.addEventListener('keydown', e => {
    let isEscape = false;
    if ('key' in e) {
      isEscape = e.key === 'Escape' || e.key === 'Esc';
    } else {
      isEscape = e.keyCode === 27;
    }
    if (isEscape) {
      resetAll();
    }
  });
}

function menuInit() {
  dropdownParents.forEach(dropdownParentElem => {
    // set active on focus:
    dropdownParentElem.addEventListener('focus', () => {
      resetAll();
      setActiveParent(dropdownParentElem);
    });

    dropdownParentElem.addEventListener('click', () => {
      resetAll();
      setActiveParent(dropdownParentElem);
    });
  });

  dropdownGroups.forEach(dropdownGroup => {
    dropdownGroup.addEventListener('mouseenter', () => {
      resetAll();
      setActiveGroup(dropdownGroup);
    });

    dropdownGroup.addEventListener('mouseleave', () => {
      resetAll();
    });

    // remove active on blur:
    links = Array.prototype.slice.call(dropdownGroup.querySelectorAll('a'));
    lastLink = links[links.length - 1];

    lastLink.addEventListener('blur', e => {
      e.preventDefault();
      resetAll();
    });
  });
}

export default function() {
  menu = document.querySelectorAll('[data-menu]')[0];
  banner = document.querySelectorAll('[data-banner]')[0];
  utilityMenu = document.querySelectorAll('[data-menu-utility]')[0];
  menuOverlay = document.querySelectorAll('[data-menu-overlay]')[0];
  dropdownGroups = Array.prototype.slice.call(
    document.querySelectorAll('[data-dropdown-group]')
  );
  dropdownParents = Array.prototype.slice.call(
    document.querySelectorAll('[data-dropdown-parent]')
  );
  body = document.getElementsByTagName('body')[0];

  if (menu) {
    menuInit();
    keyboardEsc();
    window.dropdown = { resetAll };
  }
}
