import aa from 'search-insights';

/**
 * @returns {Boolean|String} False or the user token.
 */
const init = () => {
  aa('init', {
    appId: process.env.ALGOLIA_APP_ID,
    apiKey: process.env.ALGOLIA_FRONTEND_PUB_TOKEN,
    useCookie: true,
  });

  let token = false;
  aa('getUserToken', {}, (err, userToken) => {
    if (err) {
      return;
    }

    token = userToken;
  });

  return token;
};

const handleClick = (objectIDs, type) => {
  const userToken = init();

  if (userToken) {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    const queryID = searchParams.get('queryID');

    if (queryID) {
      aa('convertedObjectIDsAfterSearch', {
        userToken, // required for Node.js
        index: `${process.env.ALGOLIA_ENV}_products`,
        eventName: `${type}: Product Added to Cart after Search`,
        objectIDs,
        queryID,
      });
    } else {
      aa('convertedObjectIDs', {
        userToken, // required for Node.js
        index: `${process.env.ALGOLIA_ENV}_products`,
        eventName: `${type}: Product Added to Cart`,
        objectIDs,
      });
    }
  }
};

const productViewed = objectId => {
  const userToken = init();

  if (userToken) {
    aa('viewedObjectIDs', {
      userToken,
      index: `${process.env.ALGOLIA_ENV}_products`,
      eventName: 'Item viewed',
      objectIDs: [objectId],
    });
  }
};

export const aaInit = () => {
  const buttons = document.querySelectorAll('.aa-insights-add-to-cart');

  buttons.forEach(button => {
    button.addEventListener('click', event => {
      const element = event.currentTarget;

      let objectIds = element.dataset.objectIds;
      const type = element.dataset.type;

      try {
        objectIds = JSON.parse(objectIds);
        objectIds = objectIds.map(String);
      } catch (e) {
        objectIds = [String(objectIds)];
      }

      handleClick(objectIds, type);
    });
  });

  if (window.algoliaObjectId) {
    const objectId = String(window.algoliaObjectId);
    productViewed(objectId);
  }
};

export const filterViewed = routeState => {
  const { hierarchicalMenu } = routeState;

  const filters = [];
  const keys = Object.keys(hierarchicalMenu);
  keys.forEach(key => {
    hierarchicalMenu[key].forEach(filter => {
      const string = `${key}:${filter}`;
      filters.push(encodeURI(string));
    });
  });

  if (filters.length) {
    const userToken = init();
    aa('viewedFilters', {
      userToken,
      index: `${process.env.ALGOLIA_ENV}_products`,
      eventName: 'Filter Viewed',
      filters,
    });
  }
};
