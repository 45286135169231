import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm';
import { pauseVideo } from './video';

// vars

let prod;
let prodThumbs;
let prodSlides;

let activeThumb;
let index;
let type;
let dataIndex;
let activeVideo;

function prodHeroCarouselInit(className) {
  const prodHeroCarousel = new Glide(`.${className}`, {
    type: 'slider',
    gap: '0',
    dragThreshold: false,
    perTouch: 1,
  }).mount({ Controls });

  prodHeroCarousel.on(['mount.after', 'run'], () => {
    activeThumb = document.querySelectorAll(
      '[data-product-hero-thumb][data-active="true"]'
    )[0];

    if (activeThumb) {
      activeThumb.removeAttribute('data-active');
    }

    index = prodHeroCarousel.index;
    type = prodThumbs[index].getAttribute('data-media-type');
    prodThumbs[index].setAttribute('data-active', true);
    dataIndex.setAttribute('data-index', index);
    dataIndex.setAttribute('data-type', type);
  });

  prodHeroCarousel.on('run.before', () => {
    index = prodHeroCarousel.index;
    type = prodThumbs[index].getAttribute('data-media-type');

    if (type === 'video') {
      activeVideo = prodSlides[index].getElementsByTagName('iframe')[0];
      pauseVideo(activeVideo.id);
    }
  });

  prodThumbs.forEach(prodThumb => {
    prodThumb.addEventListener('click', () => {
      index = prodThumbs.indexOf(prodThumb);
      prodHeroCarousel.go(`=${index}`);
    });
  });

  prodHeroCarousel.mount();
}

export default function() {
  prod = document.querySelectorAll('[data-product-hero-carousel]')[0];
  prodSlides = Array.prototype.slice.call(
    document.querySelectorAll('[data-product-hero-slide]')
  );
  prodThumbs = Array.prototype.slice.call(
    document.querySelectorAll('[data-product-hero-thumb]')
  );
  dataIndex = document.querySelectorAll('[data-index]')[0];

  if (prod) {
    prodHeroCarouselInit('glide-product-hero-carousel');
  }
}
