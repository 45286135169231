import '../scss/main.scss';
// required polyfills for Webpack's dynamic imports
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.object.assign';
import 'core-js/modules/es.object.entries';

import 'ie11-custom-properties';

// global
import drilldown from './modules/drilldown';
import dropdown from './modules/dropdown';
import inplaceLabels from './modules/inplace-labels';
import offcanvas from './modules/offcanvas';
import menuToggle from './modules/menu-toggle';
import menuActive from './modules/menu-active';
import contentToggle from './modules/content-toggle';
import disableSubmitUntilValid from './modules/disable-submit-until-valid';
import video from './modules/video';
import freezeframe from './modules/freezeframe';
import updateCachedCsrfTokens from './modules/update-cached-csrf-tokens';
import chatButtons from './modules/chat-buttons';
// homepage
import heroCarousel from './modules/hero-carousel';
import tabbedContent from './modules/tabbed-content';
import carousel from './modules/carousel';
// product detail
import productHeroCarousel from './modules/product-hero-carousel';
import customOrderForm from './modules/custom-order-form';
import toolTip from './modules/tooltip';
// account
import tableToggle from './modules/table-toggle';
import tableSectionToggle from './modules/table-section-toggle';
// training courses
import trainingSessionRegistration from './modules/training-session-registration';
import { aaInit } from './modules/algolia-analytics';
// map
import initRegionPopup from './modules/regions-toggle';

(async () => {
  // Load Vue app
  const { default: vueInit } = await import('./vue/index');

  // Initialize the Vue app - all other JS must be run *after* Vue is
  // instantiated or it will clobber event handlers
  vueInit().then(() => {
    // Prevent FOUC
    document.documentElement.className = document.documentElement.className.replace(
      'no-js',
      'js'
    );

    drilldown();
    dropdown();
    inplaceLabels();
    offcanvas();
    menuToggle();
    menuActive();
    contentToggle();
    disableSubmitUntilValid();
    heroCarousel();
    tabbedContent();
    carousel();
    productHeroCarousel();
    customOrderForm();
    toolTip();
    tableToggle();
    tableSectionToggle();
    video();
    freezeframe();
    trainingSessionRegistration();
    updateCachedCsrfTokens();
    chatButtons();
    aaInit();
    initRegionPopup();
  });
})();
