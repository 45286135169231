const selector = '[data-chat-button]';

export function openChat() {
  if (typeof window.SI_API !== 'undefined') {
    window.SI_API.showPopup();

    if (window.dropdown) {
      window.dropdown.resetAll();
    }
  }
}

export default function() {
  document.addEventListener(
    'click',
    event => {
      if (event.target.matches(`${selector}, ${selector} *`)) {
        event.preventDefault();

        openChat();
      }
    },
    false
  );
}
