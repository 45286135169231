import Glide, {
  Autoplay,
  Breakpoints,
  Controls,
  Keyboard,
} from '@glidejs/glide/dist/glide.modular.esm';

// vars

let activeSlideTxt;
let prevActiveSlideTxt;
let nextActiveSlideTxt;
let index;
let next;
let prev;

// glide init

function heroCarouselInit(imgClassName, txtClassName) {
  const txtElement = document.getElementsByClassName(`${txtClassName}`)[0];
  const txtSlides = txtElement.querySelectorAll('[data-hero-slide]');
  const autoplayDuration = 10000;

  const imgCarousel = new Glide(`.${imgClassName}`, {
    type: 'slider',
    gap: '0',
    autoplay: autoplayDuration,
    dragThreshold: false,
    swipeThreshold: false,
  });
  window.glide = imgCarousel;

  imgCarousel.mount({ Controls });

  const txtCarousel = new Glide(`.${txtClassName}`, {
    type: 'slider',
    gap: '0',
    autoplay: autoplayDuration,
    dragThreshold: false,
    swipeThreshold: false,
    keyboard: false,
    breakpoints: {
      768: {
        swipeThreshold: true,
      },
    },
  });

  txtCarousel.on(['mount.after', 'run'], () => {
    index = txtCarousel.index;
    imgCarousel.go(`=${index}`);
    activeSlideTxt = txtSlides[index].getElementsByClassName('animate')[0];
    if (activeSlideTxt) {
      activeSlideTxt.classList.add('active');
    }
  });

  txtCarousel.on('run.after', () => {
    index = txtCarousel.index;

    if (index === 0) {
      next = index + 1;
      prev = txtSlides.length - 1;
    } else if (index === txtSlides.length - 1) {
      next = 0;
      prev = index - 1;
    } else {
      next = index + 1;
      prev = index - 1;
    }

    prevActiveSlideTxt = txtSlides[prev].getElementsByClassName('animate')[0];
    if (prevActiveSlideTxt) {
      prevActiveSlideTxt.classList.remove('active');
    }

    nextActiveSlideTxt = txtSlides[next].getElementsByClassName('animate')[0];
    if (nextActiveSlideTxt) {
      nextActiveSlideTxt.classList.remove('active');
    }
  });

  txtCarousel.mount({ Autoplay, Breakpoints, Controls, Keyboard });
  window.glide = txtCarousel;

  // Advance carousel when clicking image
  document.querySelector(`.${imgClassName}`).addEventListener('click', () => {
    txtCarousel.go('>');
  });
  Array.from(
    document.querySelector(`.${txtClassName}`).querySelectorAll('li')
  ).forEach(item => {
    item.addEventListener('click', event => {
      if (event.target === item) {
        txtCarousel.go('>');
      }
    });
  });
}

export default function() {
  const hero = document.querySelectorAll('[data-hero]')[0];
  if (hero) {
    heroCarouselInit('glide-img-carousel', 'glide-txt-carousel');
  }
}
