// vars

const fieldClass = 'js-inplace-label';
const activeFieldClass = 'js-inplace-label-active';

let wrappers;

function updateState(wrapper, input) {
  // does not contain active classes and input value not empty
  if (!wrapper.classList.contains(activeFieldClass) && input.value !== '') {
    wrapper.classList.add(activeFieldClass);
  } else if (
    wrapper.classList.contains(activeFieldClass) &&
    input.value === ''
  ) {
    // does contain input classes and input value is empty
    wrapper.classList.remove(activeFieldClass);
  }
  // wrapper.classList.toggle(activeFieldClass, input.value !== '');
}

function initElement(wrapper) {
  const input = wrapper.querySelector(
    'input:not([type="file"]), textarea, select'
  );

  if (input) {
    updateState(wrapper, input);

    input.addEventListener(
      'input',
      () => {
        updateState(wrapper, input);
      },
      { passive: true }
    );

    input.addEventListener(
      'change',
      () => {
        updateState(wrapper, input);
      },
      { passive: true }
    );
  }
}

function initialize() {
  wrappers = document.querySelectorAll(`.${fieldClass}`);
  [...wrappers].forEach(wrapper => initElement(wrapper));
}

export { initialize as default, initElement };
