import Freezeframe from 'freezeframe';

let freezeframes;

function freezeframeInit() {
  freezeframes.forEach(freezeframe => {
    const freezeframeInner = freezeframe.querySelectorAll(
      '[data-freezeframe-img]'
    )[0];
    const freezeframeInnerId = freezeframeInner.getAttribute('id');
    const freezeframeInstance = new Freezeframe({
      selector: `#${freezeframeInnerId}`,
      trigger: false,
    });

    freezeframe.addEventListener('mouseenter', () => {
      freezeframeInstance.start();
    });

    freezeframe.addEventListener('mouseleave', () => {
      freezeframeInstance.stop();
    });
  });
}

export default function() {
  freezeframes = document.getElementsByClassName('freezeframe');

  if (freezeframes.length) {
    freezeframeInit();
  }
}
