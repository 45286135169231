// imports

import { throttle } from 'throttle-debounce';

// vars

let header;
let location;
let banner;

const setActive = throttle(250, () => {
  location = window.pageYOffset || document.documentElement.scrollTop;

  if (location >= 10) {
    header.setAttribute('data-active', true);

    if (
      header.getAttribute('data-header-theme') !== 'color' ||
      header.getAttribute('data-header-theme') !== 'blue'
    ) {
      header.setAttribute('data-header-theme-active', true);
    }
  } else {
    header.removeAttribute('data-active');

    if (
      header.getAttribute('data-header-theme') !== 'color' ||
      header.getAttribute('data-header-theme') !== 'blue'
    ) {
      header.removeAttribute('data-header-theme-active');
    }
  }

  if (banner) {
    const bannerYPos = Math.abs(banner.getBoundingClientRect().top);
    const bannerHeight = banner.offsetHeight;

    if (bannerYPos <= bannerHeight) {
      header.classList.remove('fixed');
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
      header.classList.add('fixed');
    }
  } else {
    header.classList.remove('sticky');
    header.classList.add('fixed');
  }
});

function onScroll() {
  window.addEventListener('scroll', () => {
    setActive();
  });
}

function onLoad() {
  window.addEventListener('load', () => {
    setActive();
  });
}

export default function() {
  header = document.querySelectorAll('header')[0];
  banner = document.querySelectorAll('[data-banner]')[0];

  if (header) {
    onLoad();
    onScroll();
  }
}
