function inViewport(elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.left >= 0 &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default inViewport;
