// vars

const formSelector = '[data-disable-submit-until-valid]';

function initialize(form) {
  let requiredInputs = [];
  let submitButtons = [];

  function checkValidity() {
    let valid = true;

    requiredInputs.forEach(input => {
      if (!input.validity.valid || (input.type === 'hidden' && !input.value)) {
        valid = false;
        input.classList.add('shadow-outline-red');
      } else {
        input.classList.remove('shadow-outline-red');
      }
    });

    submitButtons.forEach(button => {
      button.disabled = !valid;
    });

    return valid;
  }

  function findInputsAndButtons() {
    requiredInputs = Array.from(form.querySelectorAll('[required]'));
    submitButtons = Array.from(
      form.querySelectorAll('button:not([type="button"]), input[type="submit"]')
    );

    // Bind input listeners
    requiredInputs.forEach(input => {
      input.addEventListener('input', checkValidity);
      input.addEventListener('change', checkValidity);
    });
  }

  // Prevent form submissions if invalid
  form.addEventListener('submit', event => {
    if (!checkValidity()) {
      event.preventDefault();
    }
  });

  // Re-init on DOM changes (i.e. after Vue loads)
  const observer = new MutationObserver(mutations => {
    mutations.forEach(() => {
      findInputsAndButtons();
      checkValidity();
    });
  });
  observer.observe(form, { childList: true, subtree: true });

  // Set initial state
  findInputsAndButtons();
  checkValidity();
}

export default function() {
  Array.from(document.querySelectorAll(formSelector)).forEach(form => {
    initialize(form);
  });
}
