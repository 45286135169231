import { initElement } from './inplace-labels';

//  vars

let sessionDates;
let sessionInput;
let sessionButtons;

function quoteAttr(string) {
  return `${string}` /* Forces the conversion to string. */
    .replace(/&/g, '&amp;') /* This MUST be the 1st replacement. */
    .replace(/'/g, '&apos;') /* The 4 other predefined entities, required. */
    .replace(/"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

function initSessions() {
  // Replace the text input with a select list of session dates
  const sessionSelect = document.createElement('select');
  const options = sessionDates
    .map(date => {
      return `<option value="${quoteAttr(date)}">${date}</option>`;
    })
    .join();
  sessionSelect.setAttribute('id', sessionInput.id);
  sessionSelect.setAttribute('name', sessionInput.name);
  sessionSelect.classList.add('form-select', 'w-full');
  sessionSelect.innerHTML = options;
  sessionInput.parentNode.replaceChild(sessionSelect, sessionInput);
  initElement(sessionSelect.parentNode);

  // Set the session select when clicking a button
  sessionButtons.forEach(button => {
    button.addEventListener('click', () => {
      const index = sessionButtons.indexOf(button);
      sessionSelect.selectedIndex = index;
      sessionSelect.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  });
}

export default function() {
  sessionDates = window.trainingCourseSessions || null;
  sessionInput = document.querySelector('[name="sessionDate"]');
  sessionButtons = Array.prototype.slice.call(
    document.querySelectorAll('[data-session-register]')
  );

  if (sessionDates && sessionInput && sessionButtons) {
    initSessions();
  }
}
