import Glide, {
  Breakpoints,
  Controls,
  Keyboard,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';
import inViewport from '../helpers/in-viewport';

// vars

let carousels;
let carouselSlides;
let carouselSlideLinks;
let carouselClassName;
let carouselAttr;

const opts = {};

// blue content carousels
opts.content = {
  type: 'carousel',
  gap: 10,
  dragThreshold: false,
  swipeThreshold: 80,
  perTouch: 1,
  perView: 5, // above breakpoints
  peek: 180, // above breakpoints
  animationDuration: 500,
  breakpoints: {
    360: {
      gap: 8,
      perView: 1,
      peek: 25,
    },
    768: {
      gap: 8,
      perView: 1,
      peek: 25,
      swipeThreshold: false,
    },
    1000: {
      gap: 10,
      perView: 1,
      peek: 125,
    },
    1600: {
      gap: 10,
      perView: 2,
      peek: 125,
    },
    2400: {
      gap: 10,
      perView: 3,
      peek: 180,
    },
    3000: {
      gap: 10,
      perView: 4,
      peek: 180,
    },
  },
};

opts.verticalCards = {
  type: 'carousel',
  gap: 10,
  dragThreshold: false,
  swipeThreshold: 80,
  keyboard: false,
  perTouch: 1,
  peek: 8,
  perView: 10, // above breakpoints
  animationDuration: 500,
  breakpoints: {
    360: {
      gap: 8,
      perView: 1,
    },
    414: {
      gap: 8,
      perView: 1,
    },
    600: {
      gap: 8,
      perView: 1,
    },
    768: {
      gap: 8,
      perView: 2,
      swipeThreshold: false,
    },
    800: {
      gap: 16,
      perView: 2,
    },
    1000: {
      gap: 20,
      perView: 2,
    },
    1200: {
      gap: 20,
      perView: 3,
    },
    1400: {
      gap: 20,
      perView: 3,
    },
    1600: {
      gap: 20,
      perView: 3,
    },
    1800: {
      gap: 20,
      perView: 3,
    },
    2000: {
      gap: 20,
      perView: 3,
    },
    2200: {
      gap: 20,
      perView: 3,
    },
  },
};

opts.verticalCardsXL = {
  type: 'carousel',
  gap: 10,
  dragThreshold: false,
  swipeThreshold: 80,
  keyboard: false,
  perTouch: 1,
  peek: 8,
  perView: 10, // above breakpoints
  animationDuration: 500,
  breakpoints: {
    360: {
      gap: 8,
      perView: 1,
    },
    414: {
      gap: 8,
      perView: 1,
    },
    600: {
      gap: 8,
      perView: 1,
    },
    768: {
      gap: 8,
      perView: 2,
      swipeThreshold: false,
    },
    800: {
      gap: 16,
      perView: 2,
    },
    1000: {
      gap: 20,
      perView: 2,
    },
    1200: {
      gap: 20,
      perView: 3,
    },
    1400: {
      gap: 20,
      perView: 3,
    },
    1600: {
      gap: 20,
      perView: 3,
    },
    1800: {
      gap: 20,
      perView: 3,
    },
    2000: {
      gap: 20,
      perView: 3,
    },
    2200: {
      gap: 20,
      perView: 3,
    },
  },
};

// single-item content carousel
opts.single = {
  type: 'slider',
  perView: 1,
  peek: 25,
  focusAt: 'center',
  breakpoints: {
    1200: {
      gap: 8,
      perView: 1,
      focusAt: 0,
    },
  },
};

opts.homepage = {
  type: 'carousel',
  gap: 10,
  dragThreshold: false,
  swipeThreshold: 80,
  keyboard: false,
  perTouch: 1,
  peek: 8,
  perView: 10, // above breakpoints
  animationDuration: 500,
  breakpoints: {
    360: {
      gap: 8,
      perView: 1,
    },
    414: {
      gap: 8,
      perView: 1,
    },
    600: {
      gap: 8,
      perView: 1,
    },
    768: {
      gap: 8,
      perView: 2,
      swipeThreshold: false,
    },
    800: {
      gap: 16,
      perView: 2,
    },
    1000: {
      gap: 16,
      perView: 2,
    },
    1200: {
      gap: 16,
      perView: 3,
    },
    1400: {
      gap: 16,
      perView: 3,
    },
    1600: {
      gap: 16,
      perView: 3,
    },
    1800: {
      gap: 16,
      perView: 3,
    },
    2000: {
      gap: 16,
      perView: 3,
    },
    2200: {
      gap: 16,
      perView: 3,
    },
  },
};

// white content carousels
opts.featured = {
  type: 'carousel',
  gap: 10,
  dragThreshold: false,
  swipeThreshold: 80,
  keyboard: false,
  perTouch: 1,
  perView: 3, // above breakpoints
  peek: 180, // above breakpoints
  animationDuration: 500,
  breakpoints: {
    360: {
      gap: 8,
      perView: 1,
      peek: 25,
    },
    768: {
      gap: 8,
      perView: 1,
      peek: 25,
      swipeThreshold: false,
    },
    1600: {
      gap: 10,
      perView: 1,
      peek: 180,
    },
    2400: {
      gap: 10,
      perView: 2,
      peek: 180,
    },
  },
};

// homepage carousel
opts.xlarge = {
  type: 'slider',
  gap: 16,
  swipeThreshold: 80,
  keyboard: false,
  perTouch: 1,
  perView: 3, // above breakpoints
  peek: 180, // above breakpoints
  breakpoints: {
    360: {
      gap: 16,
      perView: 1,
      peek: 25,
    },
    768: {
      gap: 16,
      perView: 1,
      peek: 25,
    },
    1600: {
      gap: 16,
      perView: 1,
      peek: 100,
    },
    2400: {
      gap: 16,
      perView: 1,
      peek: 100,
    },
  },
};

// homepage promo carousel
opts.xlargePromo = {
  type: 'slider',
  gap: 16,
  swipeThreshold: 80,
  keyboard: false,
  perTouch: 1,
  perView: 3, // above breakpoints
  breakpoints: {
    360: {
      gap: 16,
      perView: 1,
    },
    768: {
      gap: 16,
      perView: 1,
    },
    1600: {
      gap: 16,
      perView: 1,
    },
    2400: {
      gap: 16,
      perView: 1,
    },
  },
};

// product carousels
opts.product = {
  type: 'carousel',
  gap: 10,
  dragThreshold: false,
  swipeThreshold: 80,
  keyboard: false,
  perTouch: 1,
  perView: 10, // above breakpoints
  peek: 180, // above breakpoints
  animationDuration: 500,
  breakpoints: {
    360: {
      gap: 8,
      perView: 1,
      peek: 25,
    },
    414: {
      gap: 8,
      perView: 1,
      peek: 25,
    },
    600: {
      gap: 8,
      perView: 1,
      peek: 125,
    },
    768: {
      gap: 8,
      perView: 2,
      peek: 70,
      swipeThreshold: false,
    },
    800: {
      gap: 8,
      perView: 2,
      peek: 70,
    },
    1000: {
      gap: 8,
      perView: 2,
      peek: 125,
    },
    1200: {
      gap: 10,
      perView: 2,
      peek: 125,
    },
    1400: {
      gap: 10,
      perView: 3,
      peek: 180,
    },
    1600: {
      gap: 10,
      perView: 3,
      peek: 180,
    },
    1800: {
      gap: 10,
      perView: 3,
      peek: 125,
    },
    2000: {
      gap: 10,
      perView: 4,
      peek: 180,
    },
    2200: {
      gap: 10,
      perView: 4,
      peek: 125,
    },
    2400: {
      gap: 10,
      perView: 4,
      peek: 180,
    },
    2600: {
      gap: 10,
      perView: 5,
      peek: 125,
    },
    2800: {
      gap: 10,
      perView: 6,
      peek: 180,
    },
    3000: {
      gap: 10,
      perView: 6,
      peek: 125,
    },
    3200: {
      gap: 10,
      perView: 7,
      peek: 180,
    },
    3600: {
      gap: 10,
      perView: 8,
      peek: 125,
    },
    3800: {
      gap: 10,
      perView: 8,
      peek: 180,
    },
    4000: {
      gap: 10,
      perView: 9,
      peek: 125,
    },
  },
};

// media carousels
opts.media = {
  type: 'carousel',
  gap: 10,
  dragThreshold: false,
  swipeThreshold: 80,
  keyboard: false,
  perTouch: 1,
  perView: 4, // above breakpoints
  peek: {
    before: 224,
    after: 0,
  }, // above breakpoints
  animationDuration: 500,
  breakpoints: {
    768: {
      gap: 8,
      perView: 1,
      peek: 25,
      swipeThreshold: false,
    },
    1000: {
      gap: 10,
      perView: 1,
      peek: 80,
    },
    1200: {
      gap: 10,
      perView: 2,
      peek: 25,
    },
    1800: {
      gap: 10,
      perView: 2,
    },
    2200: {
      gap: 10,
      perView: 3,
    },
  },
};

// glide init

function carouselInit(className, carouselOpts) {
  const carousel = new Glide(`.${className}`, carouselOpts);
  carousel.on(['mount.after', 'run.after', 'resize', 'swipe.end'], () => {
    carouselSlides = Array.prototype.slice.call(
      document.querySelectorAll('[data-carousel-slide]')
    );
    carouselSlides.forEach(slide => {
      carouselSlideLinks = Array.prototype.slice.call(
        slide.getElementsByTagName('a')
      );
      if (inViewport(slide)) {
        slide.setAttribute('data-visible', true);
        carouselSlideLinks.forEach(link => {
          link.setAttribute('tabindex', 0);
        });
      } else {
        slide.setAttribute('data-visible', false);
        carouselSlideLinks.forEach(link => {
          link.setAttribute('tabindex', -1);
        });
      }
    });
  });

  carousel.mount({ Breakpoints, Controls, Keyboard, Swipe });
}

function carouselInitAll() {
  carousels.forEach(carouselElem => {
    carouselClassName = carouselElem.classList[0];
    carouselAttr = carouselElem.getAttribute('data-carousel');
    carouselInit(carouselClassName, opts[carouselAttr]);
  });
}

export default function() {
  carousels = Array.prototype.slice.call(
    document.querySelectorAll('[data-carousel]')
  );

  if (carousels.length) {
    carouselInitAll();
  }
}
