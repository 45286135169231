// Handles the custom order products
// Submit the form automatically as soon as a PDF file is selected

const formSelector = '[data-custom-order-form]';

function initialize(form) {
  const label = form.querySelector('label[for="order-form"]');
  const input = form.querySelector('#order-form');
  const spinner = form.querySelector('svg');

  // Add event listener
  input.addEventListener('change', () => {
    if (input.value !== '') {
      spinner.classList.remove('hidden');
      label.classList.add('disabled');
      label.classList.remove('group-hover:bg-gray-600');
      form.submit();
    }
  });
}

export default function() {
  Array.from(document.querySelectorAll(formSelector)).forEach(form => {
    initialize(form);
  });
}
