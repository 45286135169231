import { createPopper } from '@popperjs/core';

// vars

let toolTips;
let activeToolTip;

function resetAll() {
  activeToolTip = Array.prototype.slice.call(
    document.querySelectorAll('[data-tooltip-trigger][data-active="true"]')
  );

  if (activeToolTip.length) {
    activeToolTip.forEach(elem => {
      elem.removeAttribute('data-active');
    });
  }
}

function setActive(trigger) {
  if (!trigger.hasAttribute('data-active')) {
    trigger.setAttribute('data-active', true);
  } else {
    trigger.removeAttribute('data-active');
  }
}

function toolTipInit(toolTip) {
  const trigger = toolTip.querySelectorAll('[data-tooltip-trigger]')[0];
  const content = toolTip.querySelectorAll('[data-tooltip-content]')[0];

  const instance = createPopper(trigger, content, {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        enabled: false,
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom'],
        },
      },
    ],
  });

  toolTip.addEventListener('mouseenter', () => {
    instance.update();
    trigger.setAttribute('data-active', true);
  });
  toolTip.addEventListener('mouseleave', () =>
    trigger.removeAttribute('data-active')
  );
  trigger.addEventListener('click', e => {
    e.preventDefault();
    instance.update();
    setActive(trigger);
  });
}

function watchDoc() {
  document.addEventListener('click', e => {
    if (!e.target.hasAttribute('data-tooltip-trigger')) {
      resetAll();
    }
  });
}

function keyboardEsc() {
  document.addEventListener('keydown', e => {
    let isEscape = false;
    if ('key' in e) {
      isEscape = e.key === 'Escape' || e.key === 'Esc';
    } else {
      isEscape = e.keyCode === 27;
    }
    if (isEscape) {
      resetAll();
    }
  });
}

function toolTipInitAll() {
  toolTips.forEach(toolTip => {
    toolTipInit(toolTip);
  });
}

function init() {
  toolTips = Array.prototype.slice.call(
    document.querySelectorAll('[data-tooltip]')
  );

  if (toolTips.length) {
    toolTipInitAll();
    watchDoc();
    keyboardEsc();
  }
}

export { init as default, toolTipInit };
