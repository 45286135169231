// vars

const medWidth = 768;
let reset = false;
let width;

let toggleParents;
let activeParents;
let toggleSub;
let activeSubs;
let toggleIcon;

function setActive(toggleParent) {
  toggleSub = toggleParent.parentNode.querySelectorAll('[data-toggle-sub]')[0];
  toggleSub.setAttribute('data-active', true);
  toggleParent.setAttribute('data-active', true);
  toggleIcon = toggleParent.querySelectorAll('[data-toggle-icon]')[0];

  if (toggleIcon) {
    toggleIcon.setAttribute('data-active', true);
    toggleIcon.classList.add('is-open');
  }
}

function resetActive(toggleParent) {
  toggleSub = toggleParent.parentNode.querySelectorAll('[data-toggle-sub]')[0];
  toggleParent.removeAttribute('data-active');
  toggleSub.removeAttribute('data-active');
  toggleIcon = toggleParent.querySelectorAll('[data-toggle-icon]')[0];

  if (toggleIcon) {
    toggleIcon.removeAttribute('data-active');
    toggleIcon.classList.remove('is-open');
  }
}

function resetAll() {
  activeParents = Array.prototype.slice.call(
    document.querySelectorAll(
      '[data-toggle-parent="mobile"][data-active="true"]'
    )
  );
  activeSubs = Array.prototype.slice.call(
    document.querySelectorAll('[data-toggle-sub="mobile"][data-active="true"]')
  );

  activeParents.forEach(activeParent => {
    activeParent.removeAttribute('data-active');
  });

  activeSubs.forEach(activeSub => {
    activeSub.removeAttribute('data-active');
  });
}

function watchWindow() {
  window.addEventListener('resize', () => {
    width = window.innerWidth;
    if (width >= medWidth) {
      if (!reset) {
        resetAll();
      }
      reset = true;
    } else {
      reset = false;
    }
  });
}

function toggleInit() {
  toggleParents.forEach(toggleParent => {
    toggleParent.addEventListener('click', e => {
      e.preventDefault();
      width = window.innerWidth;

      if (toggleParent.getAttribute('data-toggle-parent') === 'mobile') {
        if (width < medWidth) {
          if (!toggleParent.getAttribute('data-active')) {
            setActive(toggleParent);
          } else {
            resetActive(toggleParent);
          }
        }
      } else if (!toggleParent.getAttribute('data-active')) {
        setActive(toggleParent);
      } else {
        resetActive(toggleParent);
      }
    });
  });
}

export default function() {
  toggleParents = Array.prototype.slice.call(
    document.querySelectorAll('[data-toggle-parent]')
  );

  if (toggleParents.length) {
    toggleInit();
    watchWindow();
  }
}
