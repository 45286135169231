// vars

const medWidth = 768;
let menu;
let header;
let topBar;
let menuToggle;
let bannerMask;
let body;

let isActive = false;
let width;
let menuClose;

function setActive() {
  header.classList.remove('transition-none');
  header.setAttribute('data-active', true);
  topBar.setAttribute('data-active', true);
  body.setAttribute('data-fixed', true);

  if (bannerMask) {
    bannerMask.setAttribute('data-active', true);
  }

  isActive = true;
}

function resetActive() {
  header.removeAttribute('data-active');
  topBar.removeAttribute('data-active');
  body.removeAttribute('data-fixed');

  if (bannerMask) {
    bannerMask.removeAttribute('data-active');
  }

  setTimeout(() => {
    header.classList.add('transition-none');
  }, 500);

  isActive = false;
}

function resetAll() {
  header.classList.add('transition-none');
  header.removeAttribute('data-active');
  topBar.removeAttribute('data-active');
  body.removeAttribute('data-fixed');

  if (bannerMask) {
    bannerMask.removeAttribute('data-active');
  }

  isActive = false;
}

// reset if window enlarged to desktop size
function watchWindow() {
  window.addEventListener('resize', () => {
    width = window.innerWidth;
    if (width >= medWidth && isActive) {
      resetAll();
    }
  });
}

function menuInit() {
  menuToggle.addEventListener('click', e => {
    e.preventDefault();
    if (!isActive) {
      setActive();
    } else {
      resetActive();
    }
  });

  menuClose.forEach(menuCloseLink => {
    menuCloseLink.addEventListener('click', () => {
      resetAll();
    });
  });
}

export default function() {
  menu = document.querySelectorAll('[data-menu]')[0];
  header = document.querySelectorAll('[data-header]')[0];
  topBar = document.querySelectorAll('[data-top-bar]')[0];
  menuToggle = document.querySelectorAll('[data-menu-toggle]')[0];
  bannerMask = document.querySelectorAll('[data-banner-mask]')[0];
  body = document.getElementsByTagName('body')[0];
  menuClose = Array.prototype.slice.call(
    document.querySelectorAll('[data-menu-close]')
  );

  if (menu) {
    menuInit();
    watchWindow();
  }
}
