// vars

const medWidth = 768;

let tableRowParents;

let reset = false;
let width;
let tableRowSub;
let activeParents;
let activeSubs;

function setActive(tableRowParent) {
  tableRowSub = tableRowParent.nextElementSibling;
  tableRowSub.setAttribute('data-active', true);
  tableRowSub.style.display = 'table-row';
  tableRowParent.setAttribute('data-active', true);
}

function resetActive(tableRowParent) {
  tableRowSub = tableRowParent.nextElementSibling;
  tableRowParent.removeAttribute('data-active');
  tableRowSub.style.display = 'none';
  tableRowSub.removeAttribute('data-active');
}

function resetAll() {
  activeParents = Array.prototype.slice.call(
    document.querySelectorAll('[data-table-toggle-parent][data-active="true"]')
  );
  activeSubs = Array.prototype.slice.call(
    document.querySelectorAll('[data-table-toggle-sub][data-active="true"]')
  );

  activeParents.forEach(activeParent => {
    activeParent.removeAttribute('data-active');
  });

  activeSubs.forEach(activeSub => {
    activeSub.removeAttribute('data-active');
    activeSub.style.display = 'none';
  });
}

function watchWindow() {
  window.addEventListener('resize', () => {
    width = window.innerWidth;
    if (width <= medWidth) {
      if (!reset) {
        resetAll();
      }
      reset = true;
    } else {
      reset = false;
    }
  });
}

function tableRowInit() {
  tableRowParents.forEach(tableRowParent => {
    tableRowParent.addEventListener('click', e => {
      e.preventDefault();
      width = window.innerWidth;

      if (width > medWidth) {
        if (!tableRowParent.getAttribute('data-active')) {
          setActive(tableRowParent);
        } else {
          resetActive(tableRowParent);
        }
      }
    });
  });
}

export default function() {
  tableRowParents = Array.prototype.slice.call(
    document.querySelectorAll('[data-table-toggle-parent]')
  );

  tableRowInit();
  watchWindow();
}
