const regionPopup = () => {
  const regions = document.getElementsByClassName('region');

  regions.forEach(el => {
    const regionId = el.getAttribute('data-name');
    const popups = document.getElementsByClassName(regionId);

    popups.forEach(popup => {
      popup.style.display = 'none';
      popup.classList.remove('open');
      popup.classList.add('closed');
    });
  });

  regions.forEach(el => {
    el.addEventListener('click', () => {
      const allClicked = document.getElementsByClassName('clicked');
      allClicked.forEach(clicked => {
        clicked.classList.remove('clicked');
      });

      const clicked = el.classList.contains('clicked');

      if (clicked) {
        el.classList.remove('clicked');
      } else {
        el.classList.add('clicked');
      }

      const regionId = el.getAttribute('data-name');
      const regionsPopups = document.getElementsByClassName(regionId);
      const allPopups = document.getElementsByClassName('region-map-popup');

      allPopups.forEach(pop => {
        pop.style.display = 'none';
        pop.classList.remove('open');
        pop.classList.add('closed');
      });

      regionsPopups.forEach(popup => {
        popup.style.display = 'block';
        popup.classList.remove('closed');
        popup.classList.add('open');
      });
    });
  });
};

const initRegionPopup = () => {
  regionPopup();
};

export default initRegionPopup;
