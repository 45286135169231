// imports

import { throttle } from 'throttle-debounce';

// vars

const medWidth = 768;
let menu;
let body;
let menuOverlay;
let banner;
let offcanvasParents;
let offcanvasSubOne;
let offcanvasClose;
let dropdownGroups;
let offCanvasSubOne;
let offCanvasSubTwo;
let offCanvasSubThree;

let menuActive = false;
let submenuActive = false;

let bg2Active = false;
let bg3Active = false;

let level;
let activeParents;
let activeParent;
let prevParent;
let activeSubs;
let activeSub;
let activeSubList;
let prevSub;
let width;
let menuClose;

// checking scroll position for Firefox:
let oldScrollY;
let newScrollY;

const resetAll = throttle(250, () => {
  if (menuActive) {
    activeParents = Array.prototype.slice.call(
      document.querySelectorAll('[data-offcanvas-parent][data-active="true"]')
    );
    activeParents.forEach(activeParentElem => {
      activeParentElem.removeAttribute('data-active');
    });

    activeSubs = Array.prototype.slice.call(
      document.querySelectorAll('[data-offcanvas-sub][data-active="true"]')
    );
    activeSubs.forEach(activeSubElem => {
      activeSubElem.removeAttribute('data-active');
      activeSubList = activeSubElem.querySelectorAll(
        '[data-offcanvas-sub-list]'
      )[0];
      activeSubList.scrollTo({ top: 0 });
    });

    // enable dropdowns and remove overlay:
    body.removeAttribute('data-fixed');
    menuOverlay.removeAttribute('data-active');

    if (banner) {
      banner.removeAttribute('data-active');
    }

    dropdownGroups.forEach(dropdownGroup => {
      dropdownGroup.removeAttribute('data-disabled');
    });

    submenuActive = false;
    menuActive = false;
    bg2Active = false;
    bg3Active = false;
  }
});

function setActive(offcanvasParent) {
  width = window.innerWidth;

  if (width >= medWidth) {
    level = parseInt(offcanvasParent.getAttribute('data-offcanvas-parent'), 10);

    switch (level) {
      case 0:
        activeSub = document.querySelectorAll(
          `[data-offcanvas-sub="3"][data-active="true"]`
        )[0];
        if (activeSub) {
          activeSub.classList.add('md:transition-left');
          activeSub.removeAttribute('data-active');
          activeSubList = activeSub.querySelectorAll(
            '[data-offcanvas-sub-list]'
          )[0];
          activeSubList.scrollTo({ top: 0 });
        }

        activeParent = document.querySelectorAll(
          `[data-offcanvas-parent="3"][data-active="true"]`
        )[0];

        if (activeParent) {
          activeParent.removeAttribute('data-active');
        }

        break;
      case 1:
        // disable dropdowns and set overlay:

        oldScrollY = window.scrollY;

        body.setAttribute('data-fixed', true);
        menuOverlay.setAttribute('data-active', true);

        if (banner) {
          banner.setAttribute('data-active', true);
        }

        dropdownGroups.forEach(dropdownGroup => {
          dropdownGroup.setAttribute('data-disabled', true);
        });

        offCanvasSubOne = offcanvasParent.parentNode.querySelectorAll(
          `[data-offcanvas-sub="1"]`
        )[0];
        offCanvasSubOne.setAttribute('data-active', true);
        offcanvasParent.setAttribute('data-active', true);
        menuActive = true;
        break;
      case 2:
        offCanvasSubTwo = offcanvasParent.parentNode.querySelectorAll(
          `[data-offcanvas-sub="2"]`
        )[0];

        if (offCanvasSubTwo) {
          if (!bg2Active) {
            offCanvasSubTwo.classList.remove('md:transition-none');
            offCanvasSubTwo.classList.add('md:transition-left');
            offCanvasSubTwo.setAttribute('data-active', true);

            bg2Active = true;
          } else {
            offCanvasSubTwo.classList.remove('md:transition-left');
            offCanvasSubTwo.classList.add('md:transition-none');
            offCanvasSubTwo.setAttribute('data-active', true);
          }

          offcanvasParent.setAttribute('data-active', true);
          submenuActive = true;
        }

        break;
      case 3:
        offCanvasSubThree = offcanvasParent.parentNode.querySelectorAll(
          `[data-offcanvas-sub="3"]`
        )[0];

        if (offCanvasSubThree) {
          if (!bg3Active) {
            offCanvasSubThree.classList.remove('md:transition-none');
            offCanvasSubThree.classList.add('md:transition-left');
            offCanvasSubThree.setAttribute('data-active', true);

            bg3Active = true;
          } else {
            offCanvasSubThree.classList.remove('md:transition-left');
            offCanvasSubThree.classList.add('md:transition-none');
            offCanvasSubThree.setAttribute('data-active', true);
          }

          offcanvasParent.setAttribute('data-active', true);
        }

        break;
      default:
        resetAll();
    }
  }
}

function resetActive(offcanvasParent) {
  width = window.innerWidth;

  if (width >= medWidth) {
    level = parseInt(offcanvasParent.getAttribute('data-offcanvas-parent'), 10);

    if (level > 0) {
      activeParent = document.querySelectorAll(
        `[data-offcanvas-parent="${level}"][data-active="true"]`
      )[0];

      if (activeParent) {
        activeParent.removeAttribute('data-active');
      }

      activeSub = document.querySelectorAll(
        `[data-offcanvas-sub="${level}"][data-active="true"]`
      )[0];

      if (activeSub) {
        activeSub.removeAttribute('data-active');
        activeSubList = activeSub.querySelectorAll(
          '[data-offcanvas-sub-list]'
        )[0];
        activeSubList.scrollTo({ top: 0 });
      }

      prevParent = document.querySelectorAll(
        `[data-offcanvas-parent="${level + 1}"][data-active="true"]`
      )[0];

      if (prevParent) {
        prevParent.removeAttribute('data-active');
      }

      prevSub = document.querySelectorAll(
        `[data-offcanvas-sub="${level + 1}"][data-active="true"]`
      )[0];

      if (prevSub) {
        prevSub.removeAttribute('data-active');
      }
    }

    if (level === 1 && bg2Active) {
      bg2Active = false;
    }

    if ((level === 2 || level === 0) && bg3Active) {
      bg3Active = false;
    }

    if (level !== 1) {
      submenuActive = false;
    } else {
      menuActive = false;
    }
  }
}

// reset if window shrunk to smaller than desktop size
function watchWindow() {
  document.addEventListener('scroll', () => {
    width = window.innerWidth;
    if (width >= medWidth) {
      // is window actually scrolling on Firefox?
      newScrollY = window.scrollY;
      if (newScrollY !== oldScrollY) {
        resetAll();
      }
    }
  });

  window.addEventListener('resize', () => {
    width = window.innerWidth;
    if (width < medWidth && menuActive) {
      resetAll();
    }
  });
}

function keyboardEsc() {
  document.addEventListener('keydown', e => {
    let isEscape = false;
    if ('key' in e) {
      isEscape = e.key === 'Escape' || e.key === 'Esc';
    } else {
      isEscape = e.keyCode === 27;
    }
    if (isEscape) {
      resetAll();
    }
  });
}

function clickLink(offcanvasParent) {
  if (offcanvasParent.getAttribute('data-offcanvas-no-child')) {
    window.location.href = offcanvasParent.getAttribute('href');
  }
}

function menuInit() {
  offcanvasParents.forEach(offcanvasParent => {
    level = parseInt(offcanvasParent.getAttribute('data-offcanvas-parent'), 10);

    if (level === 1) {
      offcanvasParent.addEventListener('click', e => {
        e.preventDefault();
        setActive(offcanvasParent);
      });
    } else {
      offcanvasParent.addEventListener('mouseenter', () => {
        resetActive(offcanvasParent);
        setActive(offcanvasParent);
      });

      offcanvasParent.addEventListener('click', e => {
        e.preventDefault();
        resetActive(offcanvasParent);
        setActive(offcanvasParent);

        // make some links actually work on click:
        clickLink(offcanvasParent);
      });
    }
  });

  // close on click
  offcanvasClose.addEventListener('click', e => {
    e.preventDefault();
    resetAll();
  });

  // close on click
  menuClose.forEach(menuCloseLink => {
    menuCloseLink.addEventListener('click', () => {
      resetAll();
    });
  });

  // close if hovered off while submenu is open
  menuOverlay.addEventListener('mouseenter', () => {
    width = window.innerWidth;
    if (width >= medWidth && submenuActive) {
      resetAll();
    }
  });

  // close if overlay is clicked on
  menuOverlay.addEventListener('click', () => {
    width = window.innerWidth;
    if (width >= medWidth) {
      resetAll();
    }
  });

  // close if hovered off while submenu is not open
  offcanvasSubOne.addEventListener('mouseleave', () => {
    width = window.innerWidth;
    if (width >= medWidth && !submenuActive) {
      resetAll();
    }
  });
}

export default function() {
  menu = document.querySelectorAll('[data-menu]')[0];
  body = document.getElementsByTagName('body')[0];
  menuOverlay = document.querySelectorAll('[data-menu-overlay]')[0];
  banner = document.querySelectorAll('[data-banner]')[0];
  offcanvasParents = Array.prototype.slice.call(
    document.querySelectorAll('[data-offcanvas-parent]')
  );
  offcanvasSubOne = document.querySelectorAll('[data-offcanvas-sub="1"]')[0];
  offcanvasClose = document.querySelectorAll('[data-offcanvas-close]')[0];
  dropdownGroups = document.querySelectorAll('[data-dropdown-group]');
  menuClose = Array.prototype.slice.call(
    document.querySelectorAll('[data-menu-close]')
  );

  if (menu) {
    menuInit();
    watchWindow();
    keyboardEsc();
  }
}
