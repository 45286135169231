// Variables
// https://developers.google.com/youtube/iframe_api_reference
// https://developer.vimeo.com/player/sdk/basics

let thumbs;
let players;

const playersArr = [];

function setTags() {
  const head = document.getElementsByTagName('head')[0];
  const ytTag = document.createElement('script');
  const vimTag = document.createElement('script');

  ytTag.setAttribute('src', 'https://www.youtube.com/iframe_api');
  vimTag.setAttribute('src', 'https://player.vimeo.com/api/player.js');

  head.insertBefore(ytTag, head.firstChild);
  head.insertBefore(vimTag, head.firstChild);
}

// show thumb
function showThumb(id) {
  const thumbToShow = document.getElementById(id);
  thumbToShow.removeAttribute('data-active');
}

// YT player state change
function onPlayerStateChange(e) {
  const iframeId = e.target.a.id;
  const playerState = e.data;
  if (playerState === 0) {
    const thumbId = `thumb-${iframeId.split('-')[1]}`;
    showThumb(thumbId);
  }
}

// VIM player on pause
function onVideoPause() {
  const iframeId = this.element.id;
  const thumbId = `thumb-${iframeId.split('-')[1]}`;
  showThumb(thumbId);
}

// Get video object
function getVideo(id) {
  return playersArr.filter(playerObj => {
    return playerObj.id === id;
  })[0];
}

// Play video
function playVideo(id) {
  const activePlayerObj = getVideo(id);

  if (activePlayerObj.platform === 'youtube') {
    setTimeout(() => {
      activePlayerObj.player.playVideo();
    }, 500);
  } else {
    setTimeout(() => {
      activePlayerObj.player.play();

      // adding pause + end events here:
      activePlayerObj.player.on('ended', onVideoPause);
    }, 500);
  }
}

// Pause Video
function pauseVideo(id) {
  const activePlayerObj = getVideo(id);

  if (activePlayerObj.platform === 'youtube') {
    setTimeout(() => {
      activePlayerObj.player.pauseVideo();
    }, 500);
  } else {
    setTimeout(() => {
      activePlayerObj.player.pause();
    }, 500);
  }
}

function initThumbs() {
  thumbs.forEach(thumb => {
    thumb.addEventListener('click', () => {
      thumb.setAttribute('data-active', true);
      const thumbParent = thumb.parentNode;
      const player = thumbParent.getElementsByTagName('iframe')[0];
      const playerId = player.getAttribute('id');
      playVideo(playerId);
    });
  });
}

function initPlayers() {
  window.onYouTubeIframeAPIReady = function onReady() {
    players.forEach(player => {
      const iframe = player.getElementsByTagName('iframe')[0];
      const embedUrl = iframe.getAttribute('src');
      const playerId = player.getAttribute('id');
      const videoId = `video-${playerId.split('-')[1]}`;

      iframe.setAttribute('id', videoId);

      if (embedUrl.indexOf('youtube') > -1) {
        // yt init
        iframe.setAttribute('src', `${embedUrl}&enablejsapi=1&rel=0`);

        if (typeof YT === 'object') {
          const ytPlayer = new YT.Player(videoId, {
            events: {
              onStateChange: onPlayerStateChange,
            },
          });

          playersArr.push({
            id: videoId,
            player: ytPlayer,
            platform: 'youtube',
          });
        }
      } else if (embedUrl.indexOf('vimeo') > -1) {
        // vim init
        iframe.setAttribute('src', `${embedUrl}`);

        if (typeof Vimeo === 'object') {
          const vimPlayer = new Vimeo.Player(videoId);

          playersArr.push({
            id: videoId,
            player: vimPlayer,
            platform: 'vimeo',
          });
        }
      }
    });
  };
}

export default function() {
  thumbs = Array.prototype.slice.call(
    document.querySelectorAll('[data-video-thumbnail]')
  );

  players = Array.prototype.slice.call(
    document.querySelectorAll('[data-video-player]')
  );

  if (players.length && thumbs.length) {
    setTags();
    initPlayers();
    initThumbs();
  }
}

export { pauseVideo };
