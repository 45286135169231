// ensure all CSRF token inputs have a live csrf token and not a stale one in craft template cache
function initialize(input) {
  input.value = window.site.csrfToken;
}

export default function() {
  const inputSelector = `input[type=hidden][name="${window.site.csrfTokenName}"]`;

  Array.from(document.querySelectorAll(inputSelector)).forEach(initialize);
}
