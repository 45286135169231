// vars

const medWidth = 768;

let header;
let menu;
let menuToggle;
let drilldownParents;
let drilldownItems;
let drilldownBacks;
let menuBacks;
let bannerMask;

let isActive = false;
let level;
let menuSub;
let menuSubBack;
let activeParents;
let activeSubs;
let activeSubBack;
let prevSubBack;
let prevSubs;
let prevSubs2;
let activeItems;
let menuSubLists;
let menuBack;
let prevBack;
let width;

function scrollBack() {
  menu.scrollTo({ top: 0 });
  menuSubLists.forEach(menuSubList => {
    menuSubList.scrollTo({ top: 0 });
  });
}

function setActive(drilldownParent) {
  width = window.innerWidth;

  if (width < medWidth) {
    level = parseInt(drilldownParent.getAttribute('data-drilldown-parent'), 10);

    menuSub = drilldownParent.parentNode.querySelectorAll(
      `[data-drilldown-sub="${level}"]`
    )[0];

    if (level === 1) {
      drilldownItems.forEach(drilldownItem => {
        drilldownItem.setAttribute('data-off', true);
      });
    } else {
      prevSubs = Array.prototype.slice.call(
        document.querySelectorAll(`[data-drilldown-sub="${level - 1}"]`)
      );

      prevSubs.forEach(prevSub => {
        prevSub.setAttribute('data-off', true);
      });

      prevBack = document.querySelectorAll(
        `[data-menu-back="${level - 1}"]`
      )[0];

      prevBack.removeAttribute('data-active');

      activeSubBack = document.querySelectorAll(
        '[data-drilldown-back][data-active="true"]'
      )[0];

      activeSubBack.removeAttribute('data-active');
    }

    activeParents = Array.prototype.slice.call(
      document.querySelectorAll(`[data-drilldown-parent="${level}"]`)
    );

    activeParents.forEach(activeParent => {
      activeParent.setAttribute('data-off', true);
    });

    activeSubs = Array.prototype.slice.call(
      document.querySelectorAll(`[data-drilldown-sub="${level}"]`)
    );

    activeSubs.forEach(activeSub => {
      activeSub.setAttribute('data-off', true);
    });

    // back from this submenu:
    menuSubBack = menuSub.querySelectorAll('[data-drilldown-back]')[0];
    menuSubBack.setAttribute('data-active', true);

    // back from this menu level:
    menuBack = document.querySelectorAll(`[data-menu-back="${level}"]`)[0];
    menuBack.setAttribute('data-active', true);

    menuSub.classList.remove('invisible');
    menuSub.removeAttribute('data-off');
    menuSub.setAttribute('data-active', true);
    header.setAttribute('data-level', level);

    if (bannerMask) {
      bannerMask.setAttribute('data-level', level);
    }

    isActive = true;
  }
}

function resetActive(drilldownBack) {
  width = window.innerWidth;

  if (width < medWidth) {
    level = parseInt(drilldownBack.getAttribute('data-drilldown-back'), 10);

    menuSub = drilldownBack.parentNode;

    if (level === 1) {
      activeSubBack = document.querySelectorAll(
        '[data-drilldown-back][data-active="true"]'
      )[0];

      activeSubBack.removeAttribute('data-active');

      drilldownItems.forEach(drilldownItem => {
        drilldownItem.removeAttribute('data-off');
      });

      header.removeAttribute('data-level');

      if (bannerMask) {
        bannerMask.removeAttribute('data-level');
      }

      isActive = false;
    } else {
      prevSubs = Array.prototype.slice.call(
        document.querySelectorAll(`[data-drilldown-sub="${level - 1}"]`)
      );

      prevSubs.forEach(prevSub => {
        prevSub.removeAttribute('data-off');
      });

      // back from previous submenu:
      prevSubBack = menuSub.parentNode.parentNode.parentNode.querySelectorAll(
        `[data-drilldown-back="${level - 1}"]`
      )[0];

      prevSubBack.setAttribute('data-active', true);

      // back from previous menu level:
      prevBack = document.querySelectorAll(
        `[data-menu-back="${level - 1}"]`
      )[0];

      prevBack.setAttribute('data-active', true);

      header.setAttribute('data-level', level - 1);

      if (bannerMask) {
        bannerMask.setAttribute('data-level', level - 1);
      }
    }

    menuSub.removeAttribute('data-active');

    setTimeout(() => {
      menuSub.classList.add('invisible');
    }, 500);

    activeParents = Array.prototype.slice.call(
      document.querySelectorAll(`[data-drilldown-parent="${level}"]`)
    );

    activeParents.forEach(activeParent => {
      activeParent.removeAttribute('data-off');
    });

    activeSubs = Array.prototype.slice.call(
      document.querySelectorAll(`[data-drilldown-sub="${level}"]`)
    );

    activeSubs.forEach(activeSub => {
      activeSub.removeAttribute('data-off');
      activeSub.removeAttribute('data-active');
      setTimeout(() => {
        activeSub.classList.add('invisible');
      }, 500);
    });

    // back from this submenu:
    menuSubBack = menuSub.querySelectorAll('[data-drilldown-back]')[0];
    menuSubBack.removeAttribute('data-active');

    // back from this menu level:
    menuBack = document.querySelectorAll(`[data-menu-back="${level}"]`)[0];
    menuBack.removeAttribute('data-active');
  }
}

function resetAll() {
  activeItems = Array.prototype.slice.call(
    document.querySelectorAll('[data-drilldown-item][data-off="true"]')
  );
  activeParents = Array.prototype.slice.call(
    document.querySelectorAll('[data-drilldown-parent][data-off="true"]')
  );
  activeSubs = Array.prototype.slice.call(
    document.querySelectorAll('[data-drilldown-sub][data-active="true"]')
  );
  prevSubs = Array.prototype.slice.call(
    document.querySelectorAll('[data-drilldown-sub][data-off="true"]')
  );

  activeSubBack = document.querySelectorAll(
    '[data-drilldown-back][data-active="true"]'
  )[0];
  menuBack = document.querySelectorAll(
    '[data-menu-back][data-active="true"]'
  )[0];

  activeItems.forEach(active => {
    active.removeAttribute('data-off');
  });

  activeParents.forEach(activeParent => {
    activeParent.removeAttribute('data-off');
  });

  activeSubs.forEach(activeSub => {
    activeSub.removeAttribute('data-active');
    activeSub.classList.add('invisible');
  });

  prevSubs.forEach(prevSub => {
    prevSub.removeAttribute('data-off');
  });

  if (activeSubBack) {
    activeSubBack.removeAttribute('data-active');
  }

  if (menuBack) {
    menuBack.removeAttribute('data-active');
  }

  header.removeAttribute('data-level');

  if (bannerMask) {
    bannerMask.removeAttribute('data-level');
  }

  isActive = false;
}

function resetBack(menuBackElem) {
  level = parseInt(menuBackElem.getAttribute('data-menu-back'), 10);

  activeParents = Array.prototype.slice.call(
    document.querySelectorAll(`[data-drilldown-parent="${level}"]`)
  );
  activeSubs = Array.prototype.slice.call(
    document.querySelectorAll(`[data-drilldown-sub="${level}"]`)
  );

  activeSubBack = document.querySelectorAll(
    '[data-drilldown-back][data-active="true"]'
  )[0];
  activeSubBack.removeAttribute('data-active');

  switch (level) {
    case 1:
      drilldownItems.forEach(drilldownItem => {
        drilldownItem.removeAttribute('data-off');
      });

      header.removeAttribute('data-level');

      if (bannerMask) {
        bannerMask.removeAttribute('data-level');
      }

      isActive = false;

      break;
    case 2:
      prevSubs = Array.prototype.slice.call(
        document.querySelectorAll('[data-drilldown-sub="1"]')
      );
      prevSubs.forEach(prevSub => {
        prevSub.removeAttribute('data-off');
      });

      // prod catalog back:
      prevSubBack = document.querySelectorAll('[data-drilldown-back="1"]')[3];
      prevSubBack.setAttribute('data-active', true);

      // back from previous menu level:
      prevBack = document.querySelectorAll('[data-menu-back="1"]')[0];
      prevBack.setAttribute('data-active', true);

      header.setAttribute('data-level', 1);

      if (bannerMask) {
        bannerMask.setAttribute('data-level', 1);
      }

      break;
    case 3:
      prevSubs = Array.prototype.slice.call(
        document.querySelectorAll('[data-drilldown-sub="1"]')
      );

      prevSubs.forEach(prevSub => {
        prevSub.removeAttribute('data-off');
      });

      prevSubs2 = Array.prototype.slice.call(
        document.querySelectorAll('[data-drilldown-sub="2"]')
      );

      prevSubs2.forEach(prevSub2 => {
        prevSub2.removeAttribute('data-off');
        prevSub2.removeAttribute('data-active');
        setTimeout(() => {
          prevSub2.classList.add('invisible');
        }, 500);
      });

      // prod catalog back:
      prevSubBack = document.querySelectorAll('[data-drilldown-back="1"]')[3];
      prevSubBack.setAttribute('data-active', true);

      // back from previous menu level:
      prevBack = document.querySelectorAll('[data-menu-back="1"]')[0];
      prevBack.setAttribute('data-active', true);

      header.setAttribute('data-level', 1);

      if (bannerMask) {
        bannerMask.setAttribute('data-level', 1);
      }

      break;
    default:
      resetAll();
  }

  // do this in every case:
  activeParents.forEach(activeParent => {
    activeParent.removeAttribute('data-off');
  });

  activeSubs.forEach(activeSub => {
    activeSub.removeAttribute('data-off');
    activeSub.removeAttribute('data-active');
    setTimeout(() => {
      activeSub.classList.add('invisible');
    }, 500);
  });

  menuBackElem.removeAttribute('data-active');
}

// reset if window enlarged to desktop size
function watchWindow() {
  window.addEventListener('resize', () => {
    width = window.innerWidth;
    if (width >= medWidth && isActive) {
      resetAll();
    }
  });
}

function menuInit() {
  // drilldown parent link
  drilldownParents.forEach(drilldownParent => {
    drilldownParent.addEventListener('click', e => {
      e.preventDefault();
      setActive(drilldownParent);
      scrollBack();
    });
  });

  // drilldown back link
  drilldownBacks.forEach(drilldownBack => {
    drilldownBack.addEventListener('click', e => {
      e.preventDefault();
      resetActive(drilldownBack);
      scrollBack();
    });
  });

  // level back link
  menuBacks.forEach(menuBackElem => {
    menuBackElem.addEventListener('click', e => {
      e.preventDefault();
      resetBack(menuBackElem);
      scrollBack();
    });
  });

  // reset is menu is closed
  menuToggle.addEventListener('click', e => {
    e.preventDefault();
    if (isActive) {
      resetAll();
      scrollBack();
    }
  });
}

export default function() {
  header = document.querySelectorAll('[data-header]')[0];
  menu = document.querySelectorAll('[data-menu]')[0];
  menuToggle = document.querySelectorAll('[data-menu-toggle]')[0];
  bannerMask = document.querySelectorAll('[data-banner-mask]')[0];

  drilldownParents = Array.prototype.slice.call(
    document.querySelectorAll('[data-drilldown-parent]')
  );
  drilldownItems = Array.prototype.slice.call(
    document.querySelectorAll('[data-drilldown-item]')
  );
  drilldownBacks = Array.prototype.slice.call(
    document.querySelectorAll('[data-drilldown-back]')
  );
  menuBacks = Array.prototype.slice.call(
    document.querySelectorAll('[data-menu-back]')
  );
  menuSubLists = Array.prototype.slice.call(
    document.querySelectorAll('[data-drilldown-sub-list]')
  );

  if (menu) {
    menuInit();
    watchWindow();
  }
}
