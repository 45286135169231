// vars

const medWidth = 768;

let toggleParents;

let reset = false;
let width;
let toggleSub;
let toggleParentButton;
let toggleParentIcon;
let activeParents;
let activeSubs;

function setActive(toggleParent) {
  toggleSub = toggleParent.parentNode.nextElementSibling;
  toggleSub.setAttribute('data-active', true);
  toggleParent.setAttribute('data-active', true);
  toggleParentButton = toggleParent.querySelectorAll(
    '[data-table-section-toggle-parent-text]'
  )[0];
  if (toggleParentButton) {
    toggleParentButton.innerHTML = toggleParentButton.getAttribute(
      'data-active-hide'
    );
  }
  toggleParentIcon = toggleParent.getElementsByClassName('plus-minus')[0];
  toggleParentIcon.classList.add('is-open');
}

function resetActive(toggleParent) {
  toggleSub = toggleParent.parentNode.nextElementSibling;
  toggleParent.removeAttribute('data-active');
  toggleSub.removeAttribute('data-active');
  toggleParentButton = toggleParent.querySelectorAll(
    '[data-table-section-toggle-parent-text]'
  )[0];
  if (toggleParentButton) {
    toggleParentButton.innerHTML = toggleParentButton.getAttribute(
      'data-active-show'
    );
  }
  toggleParentIcon = toggleParent.getElementsByClassName('plus-minus')[0];
  toggleParentIcon.classList.remove('is-open');
}

function resetAll() {
  activeParents = Array.prototype.slice.call(
    document.querySelectorAll(
      '[data-table-section-toggle-parent][data-active="true"]'
    )
  );
  activeSubs = Array.prototype.slice.call(
    document.querySelectorAll(
      '[data-table-section-toggle-sub][data-active="true"]'
    )
  );

  activeParents.forEach(activeParent => {
    activeParent.removeAttribute('data-active');
    toggleParentButton = activeParent.querySelectorAll(
      '[data-table-section-toggle-parent-text]'
    )[0];
    if (toggleParentButton) {
      toggleParentButton.innerHTML = toggleParentButton.getAttribute(
        'data-active-show'
      );
    }
    toggleParentIcon = toggleParent.getElementsByClassName('plus-minus')[0];
    toggleParentIcon.classList.remove('is-open');
  });

  activeSubs.forEach(activeSub => {
    activeSub.removeAttribute('data-active');
  });
}

function watchWindow() {
  window.addEventListener('resize', () => {
    width = window.innerWidth;
    if (width >= medWidth) {
      if (!reset) {
        resetAll();
      }
      reset = true;
    } else {
      reset = false;
    }
  });
}

function toggleInit() {
  toggleParents.forEach(toggleParent => {
    toggleParent.addEventListener('click', e => {
      e.preventDefault();
      width = window.innerWidth;

      if (width < medWidth) {
        if (!toggleParent.getAttribute('data-active')) {
          setActive(toggleParent);
        } else {
          resetActive(toggleParent);
        }
      }
    });
  });
}

export default function() {
  toggleParents = Array.prototype.slice.call(
    document.querySelectorAll('[data-table-section-toggle-parent]')
  );

  toggleInit();
  watchWindow();
}
